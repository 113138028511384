import React from "react";
import ProductDetails from "../../Components/product/ProductDetails";

function ProductDetailsPage() {
  return (
    <>
      <ProductDetails />
    </>
  );
}

export default ProductDetailsPage;
